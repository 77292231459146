<template>
  <v-container class="smm-client-container">
    <v-row justify="center">
      <template v-for="widget in tabs">
        <v-col cols="11" md="4" :key="widget.id">
          <Widget
            v-if="widget"
            class="widget-container"
            :widget="widget"
            :isShowCloseIcon="false"
            ><template v-if="widget.component">
              <component
                :is="widget.component"
                :isCanChangePostOrStoriesMedia="false" /></template
          ></Widget>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import MediaLibrary from "@/components/dashboard/widgets/MediaLibrary";
import Communication from "@/components/dashboard/widgets/Communication";
import ClientInfo from "@/components/dashboard/widgets/ClientInfo";
import Widget from "@/components/dashboard/Widget";
import { mapMutations } from "vuex";

export default {
  name: "SmmClientPage",
  components: {
    Communication,
    ClientInfo,
    Widget,
  },
  data: () => ({
    tabs: [
      {
        id: 1,
        header: "Client Info Widget",
        isActive: false,
        component: ClientInfo,
      },
      {
        id: 2,
        header: "Communication Widget",
        isActive: false,
        component: Communication,
      },
      {
        id: 3,
        header: "Media Widget",
        isActive: false,
        component: MediaLibrary,
      },
    ],
  }),
  methods: {
    ...mapMutations("mediaLibraryWidget", ["clearMediaLibrarySelections"]),
  },
  beforeDestroy() {
    this.clearMediaLibrarySelections();
  },
};
</script>

<style scoped lang="scss">
.smm-client-container {
  .media-library {
    height: calc(100vh - 95px);
  }
  .client-info-container {
    max-width: 650px;
  }
}
</style>
